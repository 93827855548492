<template>
  <div>
    <div class="d-flex">
      <!-- Activation totals -->
      <div class="activation-filters flex-grow-1">
        <span>
          <strong>
            {{ totalItems }}
          </strong>&nbsp;
          <span>
            {{ texts.totalItems }}
          </span>
        </span>
        <span class="ml-2">
          <strong>
            {{ totalItemsToTreat }}
          </strong>&nbsp;
          <span>
            {{ texts.totalItemstoTreat }}
          </span>
        </span>
      </div>
      <!-- Search field -->
      <div class="activation-filters flex-grow-1 d-flex justify-end">
        <v-text-field
          v-if="mediumDisplay || bigDisplay"
          v-model="q"
          :append-icon="q ? null : 'search'"
          class="searchbox"
          :placeholder="$gettext('Search')"
          dark
          solo
          flat
          clearable
          hide-details
          @keyup.enter="updateResult()"
          @click:append="updateResult()"
        />
      </div>
      <!-- Filters button -->
      <div
        class="activation-filters"
        v-if="mediumDisplay"
      >
        <v-btn
          text
          small
          dark
          pa-0
          @click="toggleFilter()"
        >
          <span
            class="text-decoration-underline"
            v-translate
          >
            Filters
          </span>
          <span
            v-if="activeFilters"
            class="ml-1"
          >
            ({{ activeFilters }})
          </span>
        </v-btn>
      </div>
    </div>
    <!-- Filters big display -->
    <div
      class="d-flex"
      v-if="bigDisplay"
    >
      <!-- Status -->
      <div class="activation-filters flex-grow-1">
        <v-select
          :items="statusItems"
          v-model="status"
          dark
          :label="$gettext('Status')"
          hide-details
          class="small-dense__input"
          :no-data-text="$gettext('No status found')"
        />
      </div>
      <!-- Exploitants -->
      <div
        v-if="isUserAuthority"
        class="activation-filters flex-grow-1"
      >
        <v-select
          :items="exploitantsItems"
          v-model="exploitant"
          dark
          item-value="id"
          item-text="commercial_name"
          :label="$gettext('Enterprise')"
          hide-details
          class="small-dense__input"
          :no-data-text="$gettext('No enterprise found')"
        />
      </div>
      <!-- Date from -->
      <div class="activation-filters flex-grow-1">
        <v-menu
          ref="menuFrom"
          :close-on-content-click="true"
          :return-value.sync="dateFrom"
          transition="scale-transition"
          content-class="white text-center"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFrom"
              :label="$gettext('Start')"
              append-icon="arrow_drop_down"
              dark
              readonly
              v-on="on"
              hide-details
              class="small-dense__input"
            />
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            scrollable
            locale="fr"
            first-day-of-week="1"
            @input="$refs.menuFrom.save(dateFrom)"
          />
        </v-menu>
      </div>
      <!-- Date to -->
      <div class="activation-filters flex-grow-1">
        <v-menu
          ref="menuTo"
          :close-on-content-click="true"
          :return-value.sync="dateTo"
          transition="scale-transition"
          content-class="white text-center"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateTo"
              :label="$gettext('End')"
              append-icon="arrow_drop_down"
              dark
              readonly
              v-on="on"
              hide-details
              class="small-dense__input"
            />
          </template>
          <v-date-picker
            v-model="dateTo"
            no-title
            scrollable
            locale="fr"
            first-day-of-week="1"
            @input="$refs.menuTo.save(dateTo)"
          />
        </v-menu>
      </div>
    </div>
    <div
      class="d-flex"
      v-if="bigDisplay"
    >
      <div class="activation-filters">
        <v-switch
          v-model="insideImpactingZone"
          color="primary"
          :label="$gettext('Impacting zone')"
          class="small-dense__switch"
          dark
          hide-details
        />
      </div>
      <!-- Cancel filters -->
      <div class="activation-filters flex-grow-1 d-flex justify-end">
        <v-btn
          text
          small
          dark
          @click="resetFilters()"
        >
          <span
            class="text-decoration-underline"
            v-translate
          >
            Cancel filters
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/services/api.helper';

import { display } from '@/settings';

import {
  SET_FILTERS_NS,
  GET_ALL_ACTIVATIONS_NS,
  RESET_FILTERS_NS,
  SET_ACTIVATION_SELECTED_NS,
} from '@/store/activations';
import { GET_EXPLOITANTS_NS } from '@/store/exploitants';
import { ERROR, PENDING, SUBMITTED } from '@/store/status';

export default {
  name: 'ActivationFilters',
  props: {
    display: String,
    isUserAuthority: Boolean,
  },
  computed: {
    bigDisplay() {
      return this.display === display.BIG;
    },
    mediumDisplay() {
      return this.display === display.MEDIUM;
    },
    statusItems() {
      return this.$store.state.status.activationsStatusDict;
    },
    activeFilters() {
      return this.$store.getters['activations/activeFilters'];
    },
    exploitantsItems() {
      return this.$store.state.exploitants.exploitants;
    },
    q: {
      get() {
        return this.$store.state.activations.filters.q;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.activations.filters,
          q: newValue,
        });
      },
    },
    insideImpactingZone: {
      get() {
        return this.$store.state.activations.filters.insideImpactingZone === 'True';
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.activations.filters,
          insideImpactingZone: newValue ? 'True' : null,
        });
      },
    },
    status: {
      get() {
        return this.$store.state.activations.filters.status;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.activations.filters,
          status: newValue,
        });
      },
    },
    dateFrom: {
      get() {
        return this.$store.state.activations.filters.dateFrom;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.activations.filters,
          dateFrom: newValue,
        });
      },
    },
    dateTo: {
      get() {
        return this.$store.state.activations.filters.dateTo;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.activations.filters,
          dateTo: newValue,
        });
      },
    },
    exploitant: {
      get() {
        return this.$store.state.activations.filters.exploitantId;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.activations.filters,
          exploitantId: newValue,
        });
      },
    },
    totalItems() {
      return this.$store.state.activations.itemsLength;
    },
    totalItemsToTreat() {
      const statusToTreat = [SUBMITTED, PENDING, ERROR];
      return this.$store.state.activations.activationsCollection.filter(
        (activation) => (
          statusToTreat.includes(activation.status)
          || activation.unread_message_for_authority
        ),
      ).length;
    },
    texts() {
      return {
        totalItems: this.$ngettext(
          // singular
          'activation',
          // plural
          'activations',
          this.totalItems,
        ),
        totalItemstoTreat: this.$ngettext(
          // singular
          'activation to process',
          // plural
          'activations to process',
          this.totalItemsToTreat,
        ),
      };
    },
  },
  watch: {
    status() {
      this.debouncedUpdateResult({ that: this });
    },
    exploitant() {
      this.debouncedUpdateResult({ that: this });
    },
    dateFrom() {
      this.debouncedUpdateResult({ that: this });
    },
    dateTo() {
      this.debouncedUpdateResult({ that: this });
    },
    q() {
      this.debouncedUpdateResult({ that: this });
    },
    insideImpactingZone() {
      this.debouncedUpdateResult({ that: this });
    },
  },
  created() {
    this.setDefaultFilters();
  },
  methods: {
    setDefaultFilters() {
      const today = new Date();
      const todayDate = today.toISOString().split('T')[0];
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowDate = tomorrow.toISOString().split('T')[0];

      if (this.$store.getters['authentication/isAuthorityController']) {
        this.dateFrom = todayDate;
        this.dateTo = todayDate;
      } else if (
        this.$store.getters['authentication/authorityHasActivationsPrivileges']
        && !this.$store.getters['authentication/authorityHasApprovalsPrivileges']
      ) this.dateFrom = tomorrowDate;
      else this.dateFrom = todayDate;
    },
    debouncedUpdateResult: debounce(
      ({ that }) => that.updateResult(),
      1000, // debounce for 1s
    ),
    async updateResult() {
      this.$store.dispatch(SET_ACTIVATION_SELECTED_NS, null);
      await this.$store.dispatch(GET_ALL_ACTIVATIONS_NS, { updateBoundingBox: true });
    },
    getExploitants() {
      this.$store.dispatch(GET_EXPLOITANTS_NS);
    },
    resetFilters() {
      this.$store.dispatch(RESET_FILTERS_NS);
    },
    toggleFilter() {
      this.$emit('toggle-filter');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.activation-filters {
  margin: 14px 8px 4px 0px;
}

</style>
